<template>
  <div class="container mt-3">
    <div class="alert alert-primary" role="alert" v-if="state.importPending">
      <div class="spinner-border spinner-border-sm text-dark me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      В данный момент дома в управлении автоматически импортируются. Обычно это занимает не более 5 минут.
    </div>
    <div class="d-flex">
      <p-home-form @created="load"/>
      <div class="input-group w-25 mb-auto" v-if="false">
        <p-icon class="input-group-text" name="search"/>
        <input type="text" class="form-control" placeholder="Поиск">
      </div>
    </div>
    <p-table :data="state.homes" :columns="homesColumns">
      <template v-slot:col-action="props" class="text-center">
        <span class="material-icons fs-5 pointer" @click="state.homeToRemove = props.row">cancel</span>
      </template>
    </p-table>
    <p-confirmation-modal
      v-if="state.homeToRemove != null"
      :error="state.removeHomeError"
      @accept="removeHome"
      @cancel="state.homeToRemove = null"
    >
      <template v-slot:title>
        Удаление дома
      </template>
      <template v-slot:confirmation-text>
        Вы уверены, что хотите удалить из управления дом по адресу <b>{{ state.homeToRemove.city }}
        {{ state.homeToRemove.street }} {{ state.homeToRemove.streetNumber }}</b>?
      </template>
    </p-confirmation-modal>
  </div>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PHomeForm from '@/components/forms/Home'
import PConfirmationModal from '@/components/modals/ConfirmationModal'
import PTable from '@/components/ui/Table'
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'

const homesColumns = [
  {
    name: 'index',
    getter (_, index) {
      return index + 1
    },
  },
  {
    name: 'Город',
    key: 'city',
  },
  {
    name: 'Улица',
    key: 'street',
  },
  {
    name: 'Дом',
    key: 'streetNumber',
  },
  {
    name: 'Удалить',
    key: 'action',
  },
]

const state = reactive({
  homes: [],
  homeToRemove: null,
  removeHomeError: null,
  importPending: false,
})

const store = useStore()

const removeHome = async () => {
  state.removeHomeError = null
  try {
    await api.get(urls.HOME.REMOVE, {
      params: {
        home_id: state.homeToRemove.id,
      },
    })
    state.homes = state.homes.filter(v => v.id !== state.homeToRemove.id)
    state.homeToRemove = null
  } catch (err) {
    state.removeHomeError = err.message
  }
}

const load = async () => {
  // eslint-disable-next-line camelcase
  const { import_pending, homes } = await api.get(urls.HOME.MY)
  // eslint-disable-next-line camelcase
  state.importPending = import_pending
  state.homes = homes.sort((a, b) => {
    const aStr = a.city + a.street + a.streetNumber
    const bStr = b.city + b.street + b.streetNumber
    if (aStr === bStr) {
      return 0
    } else if (aStr > bStr) {
      return 1
    }
    return -1
  })
}

onMounted(load)
</script>

<style scoped>

</style>
