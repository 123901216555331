import useVuelidate from '@vuelidate/core'
import { computed, ref } from 'vue'

export default (data, rules, callApi) => {
  const v$ = useVuelidate(rules, data)
  const requiredFilled = computed(() => {
    const notFilled = []
    for (const field in data) {
      if (rules[field] && rules[field].requiredRu && !data[field]) {
        notFilled.push(field)
      }
    }
    return notFilled.length === 0
  })

  const serverError = ref(null)
  let submitInProgress = false

  const submit = async () => {
    if (submitInProgress) {
      return
    }
    submitInProgress = true
    serverError.value = null
    const ok = await v$.value.$validate()
    if (!ok) {
      return
    }
    try {
      await callApi()
    } catch (err) {
      serverError.value = err.message
      return
    } finally {
      submitInProgress = false
    }
    v$.value.$reset()
  }

  return {
    v$,
    requiredFilled,
    serverError,
    submit,
  }
}
