<template>
  <div class="d-flex mb-3 align-items-start">
    <form v-if="state.showForm" class="d-flex">
      <p-icon class="me-3 mt-8p" name="cancel" clickable @click="state.showForm = false"/>

      <p-input class="me-3" v-model:value="state.data.city" :error="v$.city.$errors" placeholder="Город"/>
      <p-input class="me-3" v-model:value="state.data.street" :error="v$.street.$errors" placeholder="Улица"/>
      <p-input class="me-3" placeholder="Дом" v-model:value="state.data.streetNumber" :error="v$.streetNumber.$errors"/>
    </form>

    <div>
      <button class="btn btn-dark px-5 me-3 text-nowrap"
              @click.prevent="state.showForm ? submit() : state.showForm = true">
        Добавить дом
      </button>
      <p-server-error :error="serverError"/>
    </div>
  </div>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import { requiredRu } from '@/common'
import PServerError from '@/components/ui/ServerError'
import form from '@/composables/form'
import { defineEmits, reactive } from 'vue'

const state = reactive({
  data: {
    city: null,
    street: null,
    streetNumber: null,
  },
  showForm: false,
})

const emit = defineEmits(['created'])

const {
  v$,
  serverError,
  submit,
} = form(state.data, {
  city: { requiredRu },
  street: { requiredRu },
  streetNumber: { requiredRu },
}, async () => {
  await api.post(urls.HOME.NEW, state.data)
  state.data.city = null
  state.data.street = null
  state.data.streetNumber = null
  state.showForm = false
  emit('created')
})

</script>

<style scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateY(30px);
}
</style>
